import React from "react"
import Card from "../../components/card"
import { MidPageCTA } from "../../components/CTA"
import Img from "../../components/img"
import Layout from "../../components/layout"
import Testimonial from "../../components/Testimonial"
import Seo from "../../components/seo"
import { Profiles } from "../../data/assets"
import { Routes } from "../../data/routes"
import { loadGreenhouse } from "../../helpers/Greenhouse"

const Content = {
  pageName: "Careers",
  subheader: "Careers",
  header: "Help empower every student to learn",
  description: "Help empower every student to learn",
  greenHouseLink:
    "https://boards.greenhouse.io/embed/job_board?for=yuptechnologies",

  // Tutor CTA section
  tutorCTATitle: "Interested in becoming a Yup tutor?",
  tutorCTAActions: [
    {
      link: Routes.tutors,
      text: "Learn more",
    },
  ],

  // Testimonials section
  testimonials: [
    {
      testimonial: `I've been at Yup for over five years now and
        I've never seen a company that cares so much about growth -
        not only company growth, but also every single individual's
        career growth including my own!`,
      name: "Jennifer Chiang",
      profile: Profiles.jen,
      job: "Director of Customer Success",
    },
    {
      testimonial: `Last year I decided to move to San Francisco to
        join Yup full-time. It turned out to be one of the best decisions
        I ever made. The culture of the entire company is excellent —
        people truly care about one another, personally and professionally.
        One of my favorite things about Yup is how much emphasis the
        management team puts on empowering their employees.`,
      name: "Shlomi Nissan",
      profile: Profiles.shlomi,
      job: "Senior Software Engineer",
    },
  ],

  // Principles section
  principlesHeader: "Yup culture is rooted in these principles",
  principles: [
    {
      principle: "Put Families First",
      text: "We make every decision with our Yup students and their families in mind.",
      icon: "icon-families.svg",
    },
    {
      principle: "Collaborate Effectively",
      text: "We listen intently, communicate clearly, welcome new ideas, and support our team.",
      icon: "icon-collaborate.svg",
    },
    {
      principle: "Build Long Term",
      text: "We prioritize team growth and understand that we're in a marathon, not a sprint.",
      icon: "icon-rocket.svg",
    },
    {
      principle: "Embrace Excellence",
      text: "We hold ourselves to the highest standard and put our egos aside.",
      icon: "icon-excellence.svg",
    },
    {
      principle: "Seek Growth",
      text: "We push beyond our comfort zone, take intelligent risks, and grow from mistakes.",
      icon: "icon-challenges.svg",
    },
  ],
}

function Header() {
  // Original TailwindUI component: https://tailwindui.com/components/marketing/sections/header#component-2c3b25e7b9e4490edd7b6950692c0a11
  return (
    <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
      <div className="text-center">
        <h2 className="text-base font-semibold text-secondary tracking-wide uppercase">
          {Content.subheader}
        </h2>
        <h1 className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
          {Content.header}
        </h1>
      </div>
    </div>
  )
}

function GreenhouseEmbed() {
  React.useEffect(() => {
    onLoad()
  }, [])

  async function onLoad() {
    await loadGreenhouse()
    ;(window as any).Grnhse?.Iframe.autoLoad()
  }

  return <div id="grnhse_app"></div>
}

function PrinciplesSection() {
  // Original TailwindUI component: https://tailwindui.com/components/marketing/sections/feature-sections#component-64ac58e032276db96bf343a8d4f332a8
  return (
    <div className="py-12 px-4">
      <Card>
        <div className="lg:text-center">
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            {Content.principlesHeader}
          </p>
        </div>

        <div className="mt-10">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
            {Content.principles.map(principle => (
              <div key={principle.principle} className="flex">
                <dt>
                  <div className="flex items-center justify-center h-12 w-12 rounded-md border-secondary-500">
                    <Img
                      className="h-12 w-12"
                      src={principle.icon}
                      alt={principle.principle}
                    />
                  </div>
                </dt>
                <div className="pl-8">
                  <p className="text-lg leading-6 font-medium text-gray-900">
                    {principle.principle}
                  </p>
                  <dd className="mt-2 text-base text-gray-500">
                    {principle.text}
                  </dd>
                </div>
              </div>
            ))}
          </dl>
        </div>
      </Card>
    </div>
  )
}

const Careers = () => {
  return (
    <>
      <Layout>
        <Seo
          title={Content.pageName}
          description={Content.description}
          route={Routes.careers}
        />
        <Header />
        <Card className="max-w-4xl mx-auto">
          <GreenhouseEmbed />
        </Card>
        <MidPageCTA
          title={Content.tutorCTATitle}
          actions={Content.tutorCTAActions}
        />
        {Content.testimonials.map(testimonial => (
          <Testimonial
            key={testimonial.name}
            image={testimonial.profile}
            testimonial={testimonial.testimonial}
            name={testimonial.name}
            jobs={[testimonial.job]}
          />
        ))}
        <PrinciplesSection />
      </Layout>
    </>
  )
}

export default Careers
