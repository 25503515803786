export const Greenhouse = {
  init(): Promise<void> {
    return new Promise(resolve => {
      if (!document.getElementById("greenhouse-snippet")) {
        const script = document.createElement("script")
        script.setAttribute("id", "greenhouse-snippet")
        script.src =
          "https://boards.greenhouse.io/embed/job_board/js?for=yuptechnologies"
        script.addEventListener("load", () => resolve())
        document.head.appendChild(script)
      } else {
        resolve()
      }
    })
  },
}

export default Greenhouse

export async function loadGreenhouse() {
  return await Greenhouse.init()
}
